import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import AboutFour from "@/components/about-four";
import VideoOne from "@/components/video-one";
// import TestimonialsOne from "@/components/testimonials-one";
// import TeamOne from "@/components/team-one";
// import SponsorOne from "@/components/sponsor-one";
import AboutTwo from "@/components/about-two";
import { Helmet as Head } from "react-helmet";

const About = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Page">
        <Head>
          <title>Know About Nifixeci | Nifixeci</title>
          <meta
            name="description"
            content="We’re your preferred choice when it comes to eradicating pests from
            your space forever. Finest affordable solutions."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="About Us" name="About" />
        <AboutFour />
        <VideoOne />
        <AboutTwo />
        {/* <TestimonialsOne /> */}
        {/* <TeamOne extraClassName="section_border" /> */}
        {/* <SponsorOne /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default About;
